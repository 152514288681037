import {Injectable} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {IConfirmationDialog} from '../../interfaces/confirm-dialog/IConfirmDialog.interface';

@Injectable({
  providedIn: 'root',
})
export class PrimeNgConfirmDialogService implements IConfirmationDialog {
  constructor(private confirmationService: ConfirmationService) {
  }

  confirm(
    header: string,
    message: string,
    onAccept: () => void,
    onReject?: () => void,
    acceptLabel?: string,
    acceptButtonStyleClass?: string,
    rejectLabel?: string
  ) {
    this.confirmationService.confirm({
      header: header,
      message: message,
      accept: onAccept,
      reject: onReject || (() => {
      }),
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      acceptButtonStyleClass: acceptButtonStyleClass
    });
  }
}
